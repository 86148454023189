module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-react-helmet-async/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-remark-autolink-headers/gatsby-browser.js'),
      options: {"plugins":[],"offsetY":0,"className":"anchor"},
    },{
      plugin: require('../node_modules/gatsby-remark-images/gatsby-browser.js'),
      options: {"plugins":[],"maxWidth":10000,"linkImagesToOriginal":false,"showCaptions":true,"markdownCaptions":true,"wrapperStyle":"margin: 0;","backgroundColor":"none","withWebp":{"quality":80},"loading":"lazy","disableBgImageOnAlpha":false,"disableBgImage":false,"sizeByPixelDensity":false,"quality":50,"tracedSVG":false,"decoding":"async"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
